var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-wrapper"},[_c('el-scrollbar',{staticClass:"main-wrapper-scrollbar"},[_c('div',{staticClass:"wrapper-list"},[_c('div',{staticClass:"top-title"},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.examInfo.op_name))]),_c('span',{staticClass:"title-1",staticStyle:{"opacity":"0"}},[_vm._v(_vm._s(_vm.examInfo.op_name))])]),_c('img',{staticStyle:{"max-width":"100%","display":"block"},attrs:{"src":require("../../../../assets/images/student/bottom-line.png"),"alt":""}}),_c('div',{staticClass:"time-item"},[_c('div',{staticClass:"time-item-list"},[_c('div',{staticClass:"time-title-left"},[_c('span',{staticClass:"comma",staticStyle:{"margin-left":"22px"}},[_vm._v(",,,")]),_c('div',{staticClass:"item"},[_c('span',{staticClass:"score-title"},[_vm._v("本题分值："+_vm._s(_vm.examInfo.score)+"分")]),_c('span',{staticStyle:{"margin-left":"50px"}},[_vm._v("考核时间"+_vm._s(_vm.examInfo.assess_time)+"分钟")])]),_c('span',{staticClass:"comma"},[_vm._v(",,,")])]),(_vm.examInfo.exam_module_id === 42)?[_c('div',{staticClass:"start-tex",on:{"click":_vm.startTest}},[_vm._v(_vm._s(this.opId?'开始训练':'开始考试'))])]:(_vm.examInfo.exam_module_id === 43)?[(_vm.isExamOrTrain)?[_c('router-link',{staticClass:"start-tex",attrs:{"to":"/chat","target":"_blank"}},[_vm._v("开始考试")])]:[_c('router-link',{staticClass:"start-tex",attrs:{"to":{path: '/chat', query: {id: this.opId, course_id: this.courseId, chapter: this.chapter, node: this.node}},"target":"_blank"}},[_vm._v("开始训练")])]]:(_vm.examInfo.exam_module_id === 35)?[(_vm.isExamOrTrain)?[_c('router-link',{staticClass:"start-tex",attrs:{"to":{
                        path: '/student/exam/communitypromote/index',
                        query:{
                            eaxm_id:_vm.isExamOrTrain,
                              file_download: _vm.examInfo.file_download
                        }
                    },"target":"_blank"}},[_vm._v("开始考试")])]:[_c('router-link',{staticClass:"start-tex",attrs:{"to":{
                        path: '/student/trainCenter/startTraining/communitypromote/index',
                        query:{
                            op_id: _vm.examInfo.id,
                            course_id: _vm.courseId,
                              file_download:_vm.examInfo.file_download
                        }
                    },"target":"_blank"}},[_vm._v("开始训练")])]]:_vm._e()],2)]),_c('div',{staticClass:"mission-wrapper"},[_c('div',{staticClass:"task-item"},[_c('div',{staticClass:"task-content"},[_c('p',{staticClass:"text",domProps:{"innerHTML":_vm._s(_vm.examInfo.task_background)}})])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }